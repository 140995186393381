import { useState } from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { api } from '../../services/api'

import { useNavigate, useSearchParams } from 'react-router-dom'

export interface ISorteioChuteira {
  nome_completo: string
  documento: string
  data_nascimento: string
  sexo: string
  telefone: string
  tamanho: string
  foto: File | undefined
}

export function SorteioChuteira() {
  const navigate = useNavigate()
  const params = useSearchParams()
  const [action, setAction] = useState<Record<string, any>>({})

  const [field, setField] = useState<ISorteioChuteira>({
    nome_completo: '',
    documento: '',
    data_nascimento: '',
    sexo: '',
    telefone: '',
    tamanho: '',
    foto: undefined
  } as ISorteioChuteira)

  async function handleSubmit() {
    setAction({ disable: true, text: 'Enviando...' })
    await new Promise((resolve) => setTimeout(resolve, 550))
    try {
      if (field.tamanho === '')
        throw new Error('Selecione o tamanho da chuteira')

      if (field.nome_completo === '')
        throw new Error('Preencha o campo Nome Completo')

      if (field.nome_completo === '')
        throw new Error('Preencha o campo Nome Completo')

      if (field.documento === '') throw new Error('Preencha o campo CPF/CNPJ')

      if (field.data_nascimento === '')
        throw new Error('Preencha o campo Data de Nascimento')

      if (field.telefone === '') throw new Error('Preencha o campo Telefone')

      console.log(field.foto)

      if (!field.foto || Number(field.foto?.size) === 0)
        throw new Error('Selecione uma Foto para o Sorteio')

      console.log(field.foto)

      const formData = new FormData()
      formData.append('nome_completo', field.nome_completo)
      formData.append('documento', field.documento)
      formData.append('data_nascimento', field.data_nascimento)
      formData.append('tamanho', field.tamanho)
      formData.append('sexo', field.sexo)
      formData.append('telefone', field.telefone)
      formData.append('foto', field.foto!)

      await api.post('sorteios', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      setAction({ success: true, message: 'Inscrição realizada!' })
      navigate('/sorteio-chuteira?message=Obrigado', { replace: true })
    } catch (err: any) {
      setAction({ error: true, message: err.message })
    } finally {
      setTimeout(() => setAction({}), 6100)
    }
  }

  // eiiiii comentei isso aqui pra usar futuramente
  return (
    <div className="container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '20px',
          lineHeight: '2.5'
        }}
      >
        <h1
          style={{
            color: 'green',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            fontSize: '50px'
          }}
        >
          <FontAwesomeIcon
            icon={faCheckDouble}
            style={{
              fontSize: '50px',
              color: 'green'
            }}
          />
          <span>Inscrições Encerradas</span>
        </h1>
        {/* <p>
          Seu cadastro foi realizado com sucesso, agora, você está concorrendo
          um lindo par de chuteiras profissional.
        </p> */}
        <p style={{ marginBottom: '20px' }}>
          O sorteio será dia 08 de Janeiro de 2025 as 10:00 horas da manhã com
          transmissão ao vivo pelo Instagram do Capital Nacional Futebol.
        </p>
        <a href={'/sorteio-chuteira'}>Voltar</a>
      </div>
    </div>
  )

  const message = params[0].get('message')
  if (message) {
    return (
      <div className="container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '20px',
            lineHeight: '2.5'
          }}
        >
          <h1
            style={{
              color: 'green',
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              fontSize: '50px'
            }}
          >
            <FontAwesomeIcon
              icon={faCheckDouble}
              style={{
                fontSize: '50px',
                color: 'green'
              }}
            />
            <span>{message}</span>
          </h1>
          <p>
            Seu cadastro foi realizado com sucesso, agora, você está concorrendo
            um lindo par de chuteiras profissional.
          </p>
          <p style={{ marginBottom: '20px' }}>
            O sorteio será dia 08 de Janeiro de 2025 as 10:00 horas da manhã com
            transmissão ao vivo pelo Instagram do Capital Nacional Futebol.
          </p>
          <a href={'/sorteio-chuteira'}>Voltar</a>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div
        style={{
          width: '100%',
          maxWidth: '500px',
          margin: 'auto',
          marginBottom: '20px'
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>Sorteio Chuteira</h1>
        <p style={{ marginBottom: '20px' }}>
          Preencha o formulário abaixo para participar do sorteio de um lindo
          par de chuteiras profissional.
        </p>

        <h3 style={{ marginBottom: 15 }}>Ticket Capital</h3>

        <p style={{ marginBottom: 20 }}>
          A plataforma para vendas de ingressos on-line está com uma super
          promoção e você jogador e jogadora de futebol não pode perder essa
          grande oportunidade de jogar com uma das melhores chuteiras do
          mercado. Estamos falando da Chuteira de Campo Umbro Adamant Pro que
          foi produzida junto com atletas profissionais.
        </p>

        <p style={{ marginBottom: 20 }}>
          O sorteio será dia 08 de Janeiro de 2025 as 10:00 horas da manhã com
          transmissão ao vivo pelo Instagram do Capital Nacional Futebol.
        </p>

        <p style={{ marginBottom: 5 }}>
          - Modelo profissional com calce mais justo
        </p>
        <p style={{ marginBottom: 15 }}>
          - Tecnologia Diamond Grip: textura emborrachada em alto relevo, que
          garante maior aderência e precisão do chute.
        </p>
        <p style={{ marginBottom: 15 }}>
          <strong>
            CABEDAL: 80% - SINTETICO 20% - POLIESTER FORRO: 100% - POLIESTER
            <br />
            PALMILHA: 90% - EVA 10% - POLIESTER SOLA: 100% - TPU
          </strong>
        </p>

        <p style={{ marginBottom: 5 }}>Compare suas medidas com esta tabela.</p>

        <p style={{ marginBottom: 5 }}>Número - comprimento da palmilha *</p>

        {action.error && (
          <div className="alert alert-danger">
            <p>{action.message}</p>
          </div>
        )}

        {action.success && (
          <div className="alert alert-success">
            <p>{action.message}</p>
          </div>
        )}

        <div style={{ width: '100%', maxWidth: 140, marginBottom: '20px' }}>
          <select
            className="form-control"
            onChange={(e) =>
              setField({
                ...field,
                tamanho: e.currentTarget.value
              })
            }
          >
            <option value="">Selecione</option>
            <option value="37 - 24cm">37 - 24cm</option>
            <option value="38 - 25,cm">38 - 25,cm</option>
            <option value="39 - 25,5cm">39 - 25,5cm</option>
            <option value="40 - 26,5cm">40 - 26,5cm</option>
            <option value="41 - 27,5cm">41 - 27,5cm</option>
            <option value="42 - 28cm">42 - 28cm</option>
            <option value="43 - 29cm">43 - 29cm</option>
            <option value="44 - 30cm">44 - 30cm</option>
          </select>
        </div>

        <div style={{ width: '100%', marginBottom: '20px' }}>
          <label>Nome Completo *</label>
          <input
            className="form-control"
            type="text"
            value={field.nome_completo}
            onChange={(e) =>
              setField({
                ...field,
                nome_completo: e.currentTarget.value
              })
            }
          />
        </div>
        <div style={{ width: '100%', maxWidth: 265, marginBottom: '20px' }}>
          <label>Cpf/RG *</label>
          <input
            className="form-control"
            type="text"
            value={field.documento}
            onChange={(e) =>
              setField({
                ...field,
                documento: e.currentTarget.value
              })
            }
          />
        </div>
        <div style={{ width: '100%', maxWidth: 200, marginBottom: '20px' }}>
          <label>Data de Nascimento *</label>
          <input
            className="form-control"
            type="text"
            placeholder="dd/mm/aaaa"
            value={field.data_nascimento}
            onChange={(e) =>
              setField({
                ...field,
                data_nascimento: String(e.currentTarget.value)
                  .replace(/\D/g, '') // Remove caracteres não numéricos
                  .replace(/^(\d{2})(\d)/, '$1/$2') // Adiciona '/' após o dia
                  .replace(/^(\d{2}\/\d{2})(\d)/, '$1/$2') // Adiciona '/' após o mês
                  .substring(0, 10) // Limita a 10 caracteres
              })
            }
          />
        </div>
        <div style={{ width: '100%', maxWidth: 200, marginBottom: '20px' }}>
          <label>Sexo</label>
          <select
            className="form-control"
            onChange={(e) =>
              setField({
                ...field,
                sexo: e.currentTarget.value
              })
            }
          >
            <option value="">Selecione o Sexo</option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
          </select>
        </div>
        <div style={{ width: '100%', maxWidth: 240, marginBottom: '20px' }}>
          <label>Telefone *</label>
          <input
            className="form-control"
            type="phone"
            value={field.telefone}
            onChange={(e) => {
              let value = e.target.value.replace(/\D/g, '')
              value =
                value.length <= 10
                  ? value
                      .replace(/^(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{4})(\d)/, '$1-$2')
                  : value
                      .replace(/^(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{5})(\d)/, '$1-$2')

              setField({
                ...field,
                telefone: value.substring(0, 15)
              })
            }}
          />
        </div>
        <div style={{ width: '100%', marginBottom: '20px' }}>
          <label>Foto *</label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={(e) =>
              setField({
                ...field,
                foto: e?.currentTarget?.files?.[0]!
              })
            }
          />
        </div>
        <button
          className="btn-sorteio"
          onClick={handleSubmit}
          disabled={action?.disable}
        >
          <FontAwesomeIcon icon={faCheckDouble} />
          {action?.text ?? 'Enviar Inscrição'}
        </button>
      </div>
    </div>
  )
}
